import { v4 } from "uuid";

import { DS } from "~/libs";

import { Config } from "../datas";
import {
  EventWithoutAdditionalData,
  SNOWPLOW_EVENT_VERSIONS,
  SnowplowBaseDataLayerEventParams,
  SnowplowBasePlayerEventParams,
  SnowplowDataLayerEventName,
  SnowplowEventConfig,
  SnowplowEventNames,
  SnowplowEventsMapping,
  SnowplowPayload,
  SnowplowPlayerEventName,
} from "../typings/snowplow";
import { TrackingHelper } from "./trackingHelper";

export class SnowplowManager {
  private static _instance?: SnowplowManager;

  private _sessionId = v4();
  private _eventQueue: SnowplowEventConfig[] = [];
  private _batchSize = 40;
  private _batchInterval = 3000;
  private _defaultEventVersion = "1-0-0";
  private _eventTypeSchema = {
    datalayer: "be.rtbf.gdd.auvio",
    player: "be.rtbf.gdd.auvio.player",
  };

  constructor() {
    void this._batchSendLoop(this._batchSize, this._batchInterval);
  }

  static get instance() {
    return (SnowplowManager._instance = SnowplowManager._instance ?? new SnowplowManager());
  }

  async snowplowEvent(eventName: SnowplowEventNames, payload: SnowplowPayload) {
    const eventVersion = this._getEventVersion(eventName);
    const eventSchema = this._getEventTypeSchema(eventName);

    Log.analytics.log(`snowplow send [${eventName}] (v.${eventVersion} , schema ${eventSchema}) event :`, payload);

    const dataObject = {
      schema: "iglu:com.snowplowanalytics.snowplow/unstruct_event/jsonschema/1-0-0",
      data: {
        schema: `iglu:${eventSchema}/${eventName}/jsonschema/${eventVersion}`,
        data: payload,
      },
    };

    const base64dataObject = window.btoa(JSON.stringify(dataObject));

    this._eventQueue.push({
      e: "ue",
      p: this.snowplowPlatform(),
      tv: "py-1.0.1",
      ue_px: base64dataObject,
    });
  }

  /**
   * Sanitize string properties of Snowplow events to avoid errors when using btoa which is limited to latin1 191 characters set
   *
   * Using unescape even if it's deprecated to reduce payload size, like the official Snowplow tracker :
   * https://github.com/snowplow/snowplow-javascript-tracker/blob/%40snowplow/tracker-core_v3.9.0/libraries/tracker-core/src/base64.ts#L106
   *
   */
  sanitizeEventParams(payload: SnowplowPayload | undefined): SnowplowPayload | undefined {
    if (payload !== undefined)
      for (const [key, value] of Object.entries(payload)) {
        if (typeof value === "string") {
          payload[key] = unescape(encodeURIComponent(value));
        }
      }

    return payload;
  }

  private async _batchSendLoop(batchSize: number, batchInterval: number) {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      if (this._eventQueue.length > 0)
        void fetch(`${Config().SNOWPLOW.eventServerUrl}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
          keepalive: true,
          body: JSON.stringify({
            schema: "iglu:com.snowplowanalytics.snowplow/payload_data/jsonschema/1-0-0",
            data: this._eventQueue.splice(0, batchSize),
          }),
        });

      await DS.delay(batchInterval);
    }
  }

  snowplowPlatform() {
    return [DS.PlatformType.ps4, DS.PlatformType.ps5].includes(DS.platform.type) ? "cnsl" : "tv";
  }

  /**
   * Retrieves common event parameters that are specific to an event type based on the event name.
   * This method returns the corresponding parameters for either Player or Data Layer events.
   *
   * @param {keyof SnowplowEventParamsMap} eventName - The name of the event for which to retrieve specific parameters.
   * @returns {SnowplowEventTypeSpecificParams} The specific event parameters for the given event name.
   */
  getSnowplowSpecificEventTypeParams(
    eventName: keyof SnowplowEventsMapping
  ): SnowplowBaseDataLayerEventParams | SnowplowBasePlayerEventParams | EventWithoutAdditionalData {
    if (eventName in SnowplowDataLayerEventName) {
      const dataLayerEventParams: SnowplowBaseDataLayerEventParams = {
        device: "smarttv",
        platform: "app",
        event_name: eventName,
        screen_name: TrackingHelper.pageTitle,
        user_agent: Config().RTBF.userAgent,
        version: _APP_VERSION_,
      };

      return dataLayerEventParams;
    }

    if (eventName in SnowplowPlayerEventName) {
      const playerEventParams: SnowplowBasePlayerEventParams = {
        session_id: this._sessionId,
        media_id: TrackingHelper.currentMediaId ?? "",
      };

      return playerEventParams;
    }

    return {};
  }

  private _getEventTypeSchema(
    eventName: keyof SnowplowEventsMapping
  ): typeof this._eventTypeSchema[keyof typeof this._eventTypeSchema] {
    if (eventName in SnowplowDataLayerEventName) return this._eventTypeSchema.datalayer;
    if (eventName in SnowplowPlayerEventName) return this._eventTypeSchema.player;
    throw new Error("Could not find Snowplow eventType schema");
  }

  private _getEventVersion(eventName: SnowplowEventNames): string {
    const version = SNOWPLOW_EVENT_VERSIONS[eventName];
    if (version) {
      return version;
    }

    console.warn(`Version not found for event: ${eventName}, using default version ${this._defaultEventVersion}.`);
    return this._defaultEventVersion;
  }
}

export const SnowplowHelper = SnowplowManager.instance;
